<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs color="deep-purple accent-4">
            <v-tab>Warehouse Details</v-tab>
            <v-tab>User Details</v-tab>
            <v-tab>Add User</v-tab>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card
                      class="mt-10 mb-10"
                      elevation="0"
                      style="min-height: 600px"
                    >
                      <v-data-table
                        :headers="headers"
                        :items="productinfo"
                        style="background: #f5f5f5"
                        :search="search"
                        item-key="product_id"
                        flat
                      
                        class="elevation-1"
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >WareHouse Details
                            </v-toolbar-title>

                            <v-row>
                              <v-col
                                cols="12"
                                class="pa-10 mx-10 mt-7 d-flex flex-row justify-space-around"
                              >
                                <!-- <p class="mb-0">Warehouse ID: {{ orderInfo.id }}</p> -->
                                <p class="mb-0">
                                  Warehouse Name :
                                  {{ warehouseinfo.warehouse_name }}
                                </p>
                                <p class="mb-0">
                                  Warehouse Location :
                                  {{ warehouseinfo.warehouse_location }}
                                </p>
                                <!-- <p class="mb-0">
                          Total Quantity: {{ orderInfo.user_id }}
                        </p> -->

                                <v-switch
                                  v-model="singleExpand"
                                  label="Single expand"
                                ></v-switch>
                              </v-col>

                              <v-spacer></v-spacer>
                            </v-row>
                          </v-toolbar>
                        </template>

                        <!-- <template v-slot:expanded-item="{ headers, item }">
                          <td
                            :colspan="headers.length"
                            v-for="(product, index) in item.specifications"
                            :key="index"
                            class="d-flex flex-row"
                          >
                            <v-col
                              cols="12"
                              class="d-flex flex-row justify-space-around"
                            >
                              <b> {{ index + 1 }} .</b>
                              <b>Color : </b> {{ product.color }} <br />
                              <b> Size : </b> {{ product.size }}<br />
                              <b>Quantity : </b> {{ product.quantity }}<br />
                              <b> Weight : </b> {{ product.weight }}<br />
                            </v-col>
                          </td>
                        </template> -->
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card
                      class="mt-10 mb-10"
                      elevation="0"
                      style="min-height: 600px"
                    >
                      <v-data-table
                        :headers="UserDetailsHeader"
                        :items="UserArray"
                        :search="searchuser"
                        class="elevation-1"
                        style="background: #f5f5f5"
                        :loading="loading"
                        loading-text="Please wait..."
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="searchuser"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <!-- <div>
                                <v-btn color="primary" @click="AddUser">
                                  Add User
                                </v-btn>
                              </div> -->
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            v-if="item.is_active"
                            shaped
                            text
                            @click="makeInactive(item)"
                            color="primary"
                            style="padding-left:0!important;"
                          >
                            Make inactive
                          </v-btn>

                          <v-btn
                            v-else
                            shaped
                            text
                            @click="makeActive(item)"
                            color="red"
                             style="padding-left:0!important;"
                          >
                            Make Active
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card elevation="0" style="min-height: 635px">
                      <v-card-title
                        style="background: #f5f5f5"
                        class="elevation-1 pb-3"
                      >
                        <span class="headline">Add User</span>
                      </v-card-title>

                      <v-card-text style="background: #f5f5f5">
                        <v-form ref="userform" v-model="valid_add_user">
                          <v-card-text class="pt-12">
                            <v-row>
                              <v-col cols="4">
                                <v-text-field
                                  dense
                                  v-model="username"
                                  outlined
                                  label="User Name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  dense
                                  v-model="email"
                                  outlined
                                  :rules="[(v) => !!v || 'Email is required']"
                                  label="Email"
                                  type="email"
                                  required
                                ></v-text-field>
                              </v-col>

                              <v-col cols="4">
                                <v-text-field
                                  dense
                                  v-model="phone_number"
                                  outlined
                                  label="Contact Number"
                                  :rules="[
                                    (v) => !!v || 'Phone Number is required',
                                  ]"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  type="password"
                                  required
                                  v-validate="'required'"
                                  dense
                                  v-model="password"
                                  :rules="[
                                    () =>
                                      password.length >= 6 ||
                                      'Minimum 6 character is required',
                                  ]"
                                  outlined
                                  label="Password"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  type="password"
                                  required
                                  :rules="[
                                    () =>
                                      confirm_password == password ||
                                      'Both password must be matched',
                                  ]"
                                  dense
                                  v-model="confirm_password"
                                  outlined
                                  label="Confirm Password"
                                ></v-text-field>
                              </v-col>

                              <!-- <v-col cols="6">
                                <v-text-field
                                  dense
                                  v-model="phone_number"
                                  outlined
                                  label="Contact Number"
                                  :rules="[
                                    (v) => !!v || 'Phone Number is required',
                                  ]"
                                  required
                                ></v-text-field>
                              </v-col> -->

                              <!-- <v-col cols="6">
                                <v-select
                                  v-model="role"
                                  :items="rolearray"
                                  item-text="user_role"
                                  label="User Role"
                                  outlined
                                  :rules="[(v) => !!v || 'Role is required']"
                                  dense
                                ></v-select>
                              </v-col> -->
                            </v-row>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="red darken-1" @click="closeform">
                                Cancel
                              </v-btn>
                              <v-btn
                                color="#854FFF"
                                elevation="0"
                                :disabled="!valid_add_user"
                                @click="saveuser"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card-text>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    menu: false,
    menu2: false,
    dialog: false,
    username: "",
    searchuser: "",
    email: "",
    color: "",
    phone_number: "",
    password: "",
    role: "manager",
    confirm_password: "",
    text: "",
    loading: true,
    snackbar: false,
    valid_add_user: false,

    singleExpand: false,
    search: "",
    headers: [
      { text: "Product Id", value: "product_id", sortable: false },
      { text: "Product Name", value: "product_title", sortable: false },
      { text: "Total Quantity", value: "total_quantity", sortable: false },
      { text: "Purchase Price", value: "unit_price", sortable: false },
      { text: "Selling Price", value: "product_price", sortable: false },
      
    ],
    UserDetailsHeader: [
      { text: "User Name", value: "username", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Phone Number", value: "phone_number", sortable: false },
      { text: "Status", value: "actions", sortable: false },
    ],

    warehouseinfo: {},
    productinfo: [],
    specificationinfo: [],
    contact: {},
    role: "manager",
    UserArray: [],
  }),

  methods: {
    initialize() {
      axios
        .get(`productdetails/warehouse_products/${this.$route.query.id}/`)
        .then((response) => {
          this.warehouseinfo = response.data.data;
          this.productinfo = response.data.data.products;
          this.specificationinfo =
            response.data.data.products[0].specifications;
        });
    },
    getUser() {
      axios
        .get(`manager/get_manager/1/${this.$route.query.id}/`)
        .then((response) => {
          this.UserArray = response.data.data;

          this.loading = false;
          // console.log("this is array after click", this.UserArray);
        });
    },
    saveuser() {
      let datas = {
        email: this.email,
        username: this.username,
        phone_number: this.phone_number,
        warehouse_id: this.$route.query.id,
        password: this.password,
        role: this.role,
      };
      // console.log("this is array after click" , datas)
      axios.post("manager/assign_manager/", datas).then((response) => {
        if (response.data.success) {
          this.text = response.data.message;
          this.color = "success";
          this.snackbar = true;
          this.$refs.userform.reset();
        }
      });
    },
    closeform() {
      this.$refs.userform.reset();
    },
    makeInactive(item) {
  
      // const index = this.UserArray.indexOf(item);
      axios
        .post(`manager/active_manager/0/${item.manager_id}/`)
        .then((response) => {
          if (response.data.success) {
       
            this.text = response.data.message;
            this.color = "success";
             location.reload();
        //  Object.assign(this.UserArray[index], response.data.data);
          } else {
            this.text = response.data.message;
            this.color = "error";
          }
        });
    },
    makeActive(item) {
      // const index = this.UserArray.indexOf(item);
      axios
        .post(`manager/active_manager/1/${item.manager_id}/`)
        .then((response) => {
          if (response.data.success) {
            
            this.text = response.data.message;
            this.color = "success";
            //  Object.assign(this.UserArray[index], response.data.data.is_active);
             location.reload();
          } else {
            this.text = response.data.message;
            this.color = "error";
          }
        });
    },
  },
  mounted() {
    this.initialize();
    this.getUser();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>


